import React from "react"
import Layout from "../components/layout"
import ColorContent from "../components/colorContent"
import PictureContent from "../components/pictureContent"
import MarkContent from "../components/markContent"
import Banner from "../components/banner"
import LowerBanner from "../components/lowerbanner"
import Places from "../components/places"
export default ({ location }) => ( 
  <Layout location={location}>

  <Banner buttonText="contact us" 
  pageLink="/contact"
  classy="bannerContent lighttext" 
  image="/images/group-travel-banner.png"  
  alt="plane flying over an island" 
  content={[<p>Why offer an incentive travel program?</p>]}/>
  
  
    
    <section className="travelResearch">
      
      <div>
      <p className="fancyNumber"> 112<span>%</span> </p>
        <p>Travel programs can increase sales 
        productivity by 18% and produce   
        an ROI of up to 112%. 
        <em>Incentive Research Foundation</em></p>
      </div>
      
      <div>
        <p className="fancyNumber"> 3<span>x</span> </p>
        <p>Organizations that provide non cash
        rewards such as incentive travel have  
        three times higher revenue increases.<em>Aberdeen Research</em></p>
      </div>

    </section>

    <ColorContent classy="marketingContent fourConnection" 
    content={[<p className="formatter">Your event is a huge investment and paramount to your company’s strategy. 
     <div>We understand what <strong><i>wows</i></strong>, and what <strong><i>works</i></strong>.</div></p>]}/>
    
    <section className="pictureContent">

    <div className="bigBlurb" >
      <p>InComm InCentives’ Group Travel division is created from the acquisition of the seasoned company, Meridian Loyalty. 
      Originally founded in 1978, our team brings years of experience helping our client achieve their 
      business objectives—whether it is running impactful meetings or creating a once in a lifetime
       incentive travel program. We customize each event based on client needs and budget, but make 
       sure our clients and their guests are protected by maintaining PCI level 1 compliance on personal data. </p>  
      <p>Our team is active in the Industry and we are members of both Society 
      for Incentive Travel Excellence (SITE) and Meeting Professionals International 
      (MPI). Our company has also been included on the CMI 25 annual list of largest
       meeting and incentive companies focused on the North American corporate market 
       for the past 12 years in a row.</p> 
    </div>
    
    <div class="row">
    <div className="greyLine column">&nbsp;
    </div>
    <h2 className="service">Full Service Event Planning</h2>
    <div className="greyLine column">&nbsp;
    </div>
    </div>

 <article className="event">
 
  <PictureContent picture="/images/plane-window.jpg" 
  color="Blue"
  content={[<h3>Sourcing</h3>,
  <p>No matter your destination, we can find the perfect fit for you.  
      Our experienced agents work within your budget to find your  
      best options. </p>,
  <h3>Registration and Air Travel</h3>,
  <p>Each event has a customized registration system. Once this
      goes live, our dedicated air travel team works with guests to 
  coordinate arrangements.</p>]} />
  
  <PictureContent picture="/gifs/CheersHaptic.gif" 
  color="Blue"
  content={[<h3>Agency Creative Support</h3>, 
  <p>Our inhouse team of designers create a robust offering of event 
    communications and onsite materials.</p>,
  <h3>Customer Service</h3>, 
  <p>We have agents that can answer all of your attendees’ program 
  questions from trip extensions to meal requests. </p>]} />

    <PictureContent picture="/images/ops.jpg" 
    color="Blue"
  content={[<h3>On-site Operations</h3>,
  <p>Our staff offer day-of support to ensure everything runs   
    smoothly and according to plan.</p>,
  <h3>Post-event services</h3>,
  <p>After your event we make sure the billing is accurate and 
    detailed. We can also follow up with guest experience 
  surveys to measure event success.</p> ]} />

    
  </article>
  
</section>
  
  {/* <div className="Line"></div> */}

  <div className="map">
  <p>The recent places our team has been for our clients</p>
 <img src="/images/map.png"  alt="Italian Trulli"/></div>
 <Places
   locations={['Amsterdam, Netherlands',
'Angkor Wat, Cambodia',
'Athens, Greece',
'Atlanta, GA',
'Bangkok, Thailand',
'Barcelona, Spain',
'Beijing, China',
'Belfast, Ireland',
'Bermuda',
'Bilboa, Spain',
'Boston, MA',
'Bruges, Belgium',
'Budapest, Hungary',
'Buenos Aires, Argentina',
'Cabo San Lucas, Mexico',
'Cancun, Mexico',
'Chiang Mai, Thailand',
'Chicago, IL',
'Cologne, Germany',
'Costa Rica',
'Cusco, Peru',
'Dallas, Texas',
'Denver, CO',
'Detroit, MI',
'Dubai, UAE',
'Dublin, Ireland',
'Dubrovnik, Croatia',
'Dusseldorf, Germany',
'Florence, Italy',
'Ho Chi Minh City, Vietnam',
'Hong Kong, China',
'Honolulu, Hawaii',
'Hvar, Croatia',
'Iceland',
'Jamaica',
'Kona, Hawaii',
'Kotor, Montenegro',
'Lanai, Hawaii',
'Las Vegas, NV',
'Lima, Peru',
'Lisbon, Portugal',
'London, England',
'Los Cabos, Mexico',
'Machu Picchu, Peru',
'Madrid, Spain',
'Manila, Philippines',
'Marbella, Spain',
'Marrakech, Morocco',
'Martha’s Vineyard, MA',
'Maui, Hawaii',
'Mendoza, Argentina',
'Montego Bay, Jamaica',
'Napa Valley, CA',
'Nassau, Bahamas',
'New York, NY',
'Newport, RI',
'Orlando, FL',
'Palm Beach, Aruba',
'Panama City, Panama',
'Paradise Island, Bahamas',
'Peru',
'Prague, Czech Republic',
'Puerto Rico',
'Puerto Vallarta, Mexico',
'Riviera Maya, Mexico',
'Rome, Italy',
'San Diego, CA',
'San Juan, Puerto Rico',
'Scotsdale, AZ',
'Singapore',
'South Africa',
'Split, Croatia',
'Venice, Italy',
'Vienna, Austria',
'Warsaw, Poland',
'Westlake Village, CA',
'Willemstad, Curacao'
   ]}
 />
 <LowerBanner buttonText="shop now" 
     classy="lowerbannerContent" 
     image="/images/lower-banner@2x.jpg" 
      textSize="lowerBanner" 
      alt="Two people looking at a computer" 
      content={[<p className="bannerTitle"><strong>Online Shop</strong></p>,
      <p>The InComm Incentives Shop is our simplest solution for purchasing gift cards, physical or digital, in bulk. Our Shop lets you select from a large offering of gift card brands and purchase them in only a few steps. Discount options are also available on select brands.

      </p>,

      <p>No contracts, no minimums, no IT involvement.</p>
     
      
      ]}/>
  </Layout>
)